export default defineNuxtRouteMiddleware(async (to, from) => {
    
    
    if(process.client) {
        let token = sessionStorage.getItem('token')
        if (token == null){
            return await navigateTo("/login");
        }
    

    const { $parseJwt } = useNuxtApp()
    let user = useUser();
    
    let decodedToken = $parseJwt(token)

    user.value.firstname = decodedToken.firstname;
    user.value.lastname = decodedToken.lastname;
    user.value.email = decodedToken.email;

    if ("avatar" in decodedToken ){
        user.value.avatar = decodedToken.avatar;
    }

    if (decodedToken.membership.length > 0){
        let company = useCompany();
        if (decodedToken.membership.length == 1){
            company.value.name = decodedToken.membership[0].name;
            company.value.country = decodedToken.membership[0].country;
            company.value.role = decodedToken.membership[0].role;
            company.value.mode = decodedToken.membership[0].mode;
        }
    }
}
    



    // console.log("Company: ", decodedToken);


})